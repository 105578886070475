<template>
	<v-app>
		<r-app-bar/>
		<r-filter-drawer/>
		<v-main>

			<v-slide-x-transition mode="out-in">
				<router-view></router-view>
			</v-slide-x-transition>
			<r-account-deletion-alert v-if="user.subscription_status === 'Lapsed'"></r-account-deletion-alert>
			<r-new-button v-else/>
			<r-toast></r-toast>
		</v-main>
	</v-app>
</template>

<script>
import {sync} from 'vuex-pathify'

export default {
	name: "Index",
	components: {},
	computed: {
		user: sync('user/user')
	}
}
</script>